import React from "react";

import "App.css";
import AppRouter from "router/AppRouter";

const App: React.FC = () => {
  return <AppRouter />;
};

export default App;
